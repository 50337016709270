import { APP_ROUTES } from 'jernia.no/helpers/page-urls';
import { useEcommerce } from 'jernia.no/hooks/datalayer';
import { useStorePreferences } from 'jernia.no/hooks/selectedStore';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCustomerPhone } from '@jernia/shared/state/customer';
import { queryClient } from '@jernia/shared/state/query-client';

import { config } from '@jernia/shared/lib/config';

import { useAuthStore } from '../auth';
import { fetchWithAuth, fetchWithAuthOptional } from '../fetch';
import { useCart } from './queries';
import { useRemovedEntriesStore } from './removed-entries-store';
import { DeliveryMode } from './shared';
import { useCartStore } from './store';

import { Cart, CartEntry } from '@jernia/shared/types/rest/cart';
import { RestError } from '@jernia/shared/types/rest/error';
import { Order } from '@jernia/shared/types/rest/order';

/**
 * !! About cart user ids and cart ids !!
 *
 * The endpoints for the cart vary based on the cart's state.
 *
 * For carts considered "guest carts", which include:
 *  - carts assigned to the 'anonymous' user
 *  - carts assigned to a "guest user",
 * we always fetch the cart using `/users/anonymous`.
 *
 * Fetching a cart as 'anonymous' requires fetching by the 'guid'.
 * For carts connected to an actual customer, we fetch by the 'code'.
 *
 * To determine if it's a guest cart, we check if the cart's user type is 'GUEST' or if the user ID is 'anonymous'.
 */

const isAnonymousCart = (cart: Cart) =>
  cart.user.customerType === 'GUEST' || cart.user.uid == 'anonymous';

export function getCartId(cart?: Cart | null) {
  if (!cart) {
    return null;
  }

  return isAnonymousCart(cart) ? cart.guid : cart.code;
}

export function getCartUserId(cart: Cart) {
  return isAnonymousCart(cart) ? 'anonymous' : cart.user.uid;
}

export function useCreateCartMutation() {
  type Opts = {
    userId?: string;
    oldCartId?: string;
    toMergeCartGuid?: string;
    skipAuth?: boolean;
    anonymous?: boolean;
  };

  const { setCart } = useCartStore();

  return useMutation<Cart, unknown, Opts>(
    async ({
      userId = useAuthStore.getState().userId,
      oldCartId,
      toMergeCartGuid,
      skipAuth,
      anonymous,
    }) => {
      // If anonymous is true, force userId to be 'anonymous'
      const effectiveUserId = anonymous ? 'anonymous' : userId;

      // If cart is anonymous, skipAuth should be true
      const shouldSkipAuth = skipAuth || anonymous;

      let url = `${config.apiBaseUrl}/users/${effectiveUserId}/carts?fields=FULL`;

      if (oldCartId) {
        url = `${url}&oldCartId=${oldCartId}`;
      }

      if (toMergeCartGuid) {
        url = `${url}&toMergeCartGuid=${toMergeCartGuid}`;
      }

      const response = await fetchWithAuthOptional(url, {
        method: 'POST',
        skipAuth: shouldSkipAuth,
      });

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      return result;
    },
    {
      onSuccess: (cart) => {
        localStorage.setItem(
          'cartId',
          cart.user.uid === 'anonymous' ? cart.guid : cart.code
        );
        localStorage.setItem('cartUid', cart.user.uid);

        queryClient.removeQueries('cart');
        setCart(cart);
      },
    }
  );
}

export function useDeleteCartMutation({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) {
  const { data: cart } = useCart();

  const { clearCart } = useCartStore();

  return useMutation<void, RestError>(
    async () => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}`,
        { method: 'DELETE' }
      );

      if (!response.ok) {
        const result: RestError = await response.json();

        if (
          Array.isArray(result.errors) &&
          result.errors.find(
            (err) => err.reason === 'expired' || err.reason === 'notFound'
          )
        ) {
          return Promise.resolve();
        }

        throw result;
      }
    },
    {
      onSuccess: () => {
        clearCart();

        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
}

export function useAddToCartMutation() {
  const { data: cartData } = useCart();

  const removedEntriesStore = useRemovedEntriesStore();
  const createCartMutation = useCreateCartMutation();
  const cartCreationInProgress = useRef<Promise<any> | null>(null);

  type Opts = {
    productCode: string;
    quantity?: number;
    services?: string[];
    store?: string;
    comment?: string;
    skipAuth?: boolean;
    anonymous?: boolean;
  };

  type Result =
    // Only for jernia.no
    | {
        totalValue: string;
        productsInCart: string[];
      }
    | undefined;

  // Update getOrCreateCart to handle anonymous parameter
  const getOrCreateCart = async (anonymous?: boolean) => {
    if (cartData) return cartData;

    if (cartCreationInProgress.current) {
      return cartCreationInProgress.current;
    }

    cartCreationInProgress.current = createCartMutation
      .mutateAsync({ anonymous, skipAuth: anonymous })
      .then(
        (createdCart) => {
          cartCreationInProgress.current = null;
          return createdCart;
        },
        (error) => {
          cartCreationInProgress.current = null;
          throw error;
        }
      );

    return cartCreationInProgress.current;
  };

  return useMutation<Result, unknown, Opts>(
    async ({
      productCode,
      quantity = 1,
      services = [],
      store,
      comment,
      skipAuth,
      anonymous,
    }) => {
      const cart = await getOrCreateCart(anonymous);

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const addRes = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/entries`,
        {
          method: 'POST',
          skipAuth: skipAuth || anonymous,
          body: JSON.stringify({
            product: {
              code: productCode,
            },
            deliveryPointOfService: {
              name: store,
            },
            quantity: quantity,
            productContentComment: comment,
            services: services,
          }),
        }
      );

      return addRes.json();
    },
    {
      onSuccess: async (_, { productCode }) => {
        queryClient.invalidateQueries('cart');
        removedEntriesStore.removeEntry(productCode);
      },
      onError: () => {
        toast.error(
          'Produktet kunne ikke legges i handlekurven. Det er sannsynligvis utsolgt. Prøv igjen senere.'
        );
      },
    }
  );
}

export function useAddLocalProductToCartMutation() {
  let { data: cart } = useCart();

  const createCartMutation = useCreateCartMutation();

  type Opts = {
    ean: number;
  };

  return useMutation<unknown, unknown, Opts>(
    async ({ ean }) => {
      if (!cart) {
        cart = await createCartMutation.mutateAsync({});

        if (!cart) {
          return Promise.resolve();
        }
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      return fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/entries/tmp`,
        {
          sendAsForm: true,
          body: {
            ean,
          },
        }
      );
    },
    { onSuccess: () => queryClient.invalidateQueries('cart') }
  );
}

export function useUpdateQuantityMutation() {
  const { data: cart } = useCart();

  type Opts = {
    /** Entrynumber of the item in the cart */
    entryNumber: number;
    /** New quantity, will replace the old one */
    quantity: number;
  };

  return useMutation<unknown, unknown, Opts>(
    ({ entryNumber, quantity }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      return fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/entries/${entryNumber}`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            quantity: quantity,
          }),
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cart');
      },
    }
  );
}

export function useRemoveAllEntriesMutation() {
  const { data: cart } = useCart();

  return useMutation(
    async () => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      // Empty cart one by one in reverse order. We need to wait until the
      // deletion is complete before we delete the next one. Else we may end
      // up trying to delete an entry which doesn't exist, as the entryNumber
      // will change as we remove items in the cart.
      for (const entry of cart.entries.reverse()) {
        await fetchWithAuthOptional(
          `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/entries/${entry.entryNumber}`,
          {
            method: 'DELETE',
          }
        );
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries('cart'),
    }
  );
}

export function useRemoveEntryMutation() {
  const { data: cart } = useCart();
  const removedEntriesStore = useRemovedEntriesStore();

  type Opts = {
    entryNumber: number;
  };

  return useMutation<CartEntry | undefined, unknown, Opts>(
    async ({ entryNumber }) => {
      const entry = cart?.entries.find(
        (entry) => entry.entryNumber === entryNumber
      );

      if (!cart) {
        return undefined;
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/entries/${entryNumber}`,
        {
          method: 'DELETE',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to remove entry');
      }

      return entry;
    },
    {
      onSuccess: (removedEntry) => {
        queryClient.invalidateQueries('cart');

        if (removedEntry) {
          removedEntriesStore.addEntry({
            code: removedEntry.product.code,
            quantity: removedEntry.quantity,
            productContentComment: removedEntry.productContentComment,
            name: removedEntry.product.name,
            services:
              removedEntry.children?.map((child) => child.product.code) ?? [],
          });
        }
      },
    }
  );
}

export function useAddServiceMutation() {
  const { data: cart } = useCart();

  type Opts = {
    /** Entry number of the product to add the service to */
    entryNumber: number;
    serviceCode: string;
  };

  return useMutation<unknown, unknown, Opts>(
    ({ entryNumber, serviceCode }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const item = cart.entries.find(
        (entry) => entry.entryNumber === entryNumber
      );

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const payload = {
        quantity: item?.quantity ?? 1,
        children: [
          {
            product: {
              code: serviceCode,
            },
          },
        ],
      };

      return fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/entries/${entryNumber}`,
        {
          method: 'PATCH',
          body: JSON.stringify(payload),
        }
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('cart'),
    }
  );
}

export function useAssignEmailMutation({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  type Opts = {
    email: string;
    cart: Cart;
  };

  type Error = {
    message: string;
  };

  return useMutation<unknown, Error, Opts>(
    async ({ email, cart }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/email`,
        {
          method: 'PUT',
          queryParams: {
            email,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw Array.isArray(data.errors) ? data.errors[0] : data;
      }
    },
    {
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries('cart');
      },
    }
  );
}

export function useAssignAddressMutation({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) {
  let { data: cart } = useCart();

  const { userId } = useAuthStore();

  const createCartMutation = useCreateCartMutation();

  type Opts = {
    addressId: string;
  };

  return useMutation<unknown, RestError, Opts>(
    async ({ addressId }) => {
      if (!cart) {
        return Promise.resolve();
      }

      if (cart.user.uid === 'anonymous') {
        // Can't assign an address to an anonymous cart, so now we must transfer
        // the cart to the user.
        cart = await createCartMutation.mutateAsync({
          userId,
          oldCartId: cart.guid,
        });
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/addresses/delivery?addressId=${addressId}`,
        {
          method: 'PUT',
        }
      );

      if (!response.ok) {
        throw await response.json();
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cart');
        onSuccess && onSuccess();
      },
    }
  );
}

export function useCreateDeliveryAddressMutation() {
  const { data: cart } = useCart();
  const { tmpAddressInfo } = useCartStore();

  type Opts = {
    address: string;
    postalCode: string;
    town?: string;
  };

  return useMutation<unknown, unknown, Opts>(
    async ({ address, postalCode, town }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      return fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/addresses/delivery`,
        {
          method: 'POST',
          body: JSON.stringify({
            line1: address,
            postalCode,
            town,
            ...tmpAddressInfo,
          }),
        }
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('cart'),
    }
  );
}

export function useUpdateRentalPeriodMutation() {
  const { data: cart } = useCart();
  type Opts = {
    store: string;
    rentalPeriodStart: string;
    rentalPeriodEnd: string;
  };

  const cartId = getCartId(cart);
  const cartUserId = getCartUserId(cart);

  return useMutation<unknown, unknown, Opts>(
    async ({ store, rentalPeriodEnd, rentalPeriodStart }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const url = `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/rental`;
      const params = new URLSearchParams({
        pickupStore: store,
        rentalPeriodStart: rentalPeriodStart,
        rentalPeriodEnd: rentalPeriodEnd,
      });

      await fetchWithAuthOptional(`${url}?${params}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries('cart'),
    }
  );
}

export function useReserveInStoreMutation() {
  type Opts = {
    phone: string;
    email: string;
  };
  const { cartId, cartUid } = useCartStore();
  const { accessToken } = useAuthStore();

  const router = useRouter();

  return useMutation<Order, unknown, Opts>(
    async ({ phone, email }) => {
      const params = new URLSearchParams({
        phone,
        email,
      });

      const response = await fetchWithAuthOptional(
        `${
          config.apiBaseUrl
        }/users/${cartUid}/carts/${cartId}/reserve?${params.toString()}`,
        {
          method: 'POST',
        }
      );
      if (!response.ok) {
        throw await response.json();
      }

      return response.json();
    },
    {
      onSuccess: (order: Order) => {
        if (order.status === 'CREATED') {
          // NOTE check if we need to clear cart here or invalidate queries works as expected

          const id = accessToken ? order.code : order.guid;

          router
            .push(`${APP_ROUTES.CHECKOUT.ORDER_CONFIRMATION}/${id}`)
            .then(() => {
              queryClient.invalidateQueries('cart');
            });
        }
      },
    }
  );
}

export function useSetDeliveryModeMutation() {
  const { data: cart } = useCart();

  type Opts = {
    deliveryMode: DeliveryMode;
  };

  return useMutation<unknown, unknown, Opts>(
    async ({ deliveryMode }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartUserId = getCartUserId(cart);
      const cartId = getCartId(cart);

      // Assign the delivery mode to the cart

      let deliveryUrl = `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/deliverymode?deliveryModeId=${deliveryMode.deliveryId}`;

      if (deliveryMode.kind === 'deliveryservice') {
        deliveryUrl += `&deliveryModeValue=${deliveryMode.deliveryModeValue}`;
      }

      await fetchWithAuthOptional(deliveryUrl, {
        method: 'PUT',
      });

      // If the delivery mode has a drop point, assign that
      if (deliveryMode.kind === 'droppoint') {
        await fetchWithAuthOptional(
          `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/droppoint?dropPointId=${deliveryMode.droppoint}`,
          {
            method: 'PUT',
          }
        );
      } else if (deliveryMode.kind === 'deliveryservice') {
        const codes = deliveryMode.serviceCodes
          ? deliveryMode.serviceCodes.join(',')
          : '';

        await fetchWithAuthOptional(
          `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/deliveryservices?codes=${codes}`,
          {
            method: 'PUT',
          }
        );
      }

      return true;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cart');
        return;
      },
    }
  );
}

export function useDeleteDeliveryModeMutation() {
  const { data: cart } = useCart();
  return useMutation<unknown, unknown, void>(
    async () => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartUserId = getCartUserId(cart);
      const cartId = getCartId(cart);

      // Assign the delivery mode to the cart

      const deliveryUrl = `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/deliverymode`;

      await fetchWithAuthOptional(deliveryUrl, {
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries('cart'),
      mutationKey: ['deleteDeliveryMode'],
    }
  );
}

export function useCreateVippsPaymentMutation() {
  let { data: cart } = useCart();

  const { userId } = useAuthStore();
  const createCartMutation = useCreateCartMutation();
  const phone = useCustomerPhone();

  type Opts = {
    paymentMode: 'Vipps' | 'vippsB2h';
  };

  return useMutation<string, unknown, Opts>(
    'vippsPayment',
    async ({ paymentMode }) => {
      if (!cart) {
        return Promise.resolve();
      }

      if (cart.user.uid === 'anonymous') {
        // Can't assign an address to an anonymous cart, so now we must transfer
        // the cart to the user.
        cart = await createCartMutation.mutateAsync({
          userId,
          oldCartId: cart.guid,
        });
      }

      const cartUserId = getCartUserId(cart);
      const cartId = getCartId(cart);

      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/paymentmode?paymentMode=${paymentMode}&mobilePhone=${phone}&fields=FULL`,
        { method: 'POST' }
      );

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      return result.redirectUrl;
    }
  );
}

export function useCancelVippsPaymentMutation({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { data: cart } = useCart();

  return useMutation<void>(
    'cancelVippsPayment',
    async () => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartUserId = getCartUserId(cart);
      const cartId = getCartId(cart);

      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/vipps/cancel`,
        { method: 'POST' }
      );

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }
    },
    {
      onSuccess,
    }
  );
}

export function useConfirmVippsPaymentMutation() {
  const { data: cart } = useCart();
  const { clearCart } = useCartStore();
  const { resetLoggedInUser } = useAuthStore();

  type Opts = {
    userPk: string;
    orderId: string;
  };

  const router = useRouter();

  return useMutation<Order, unknown, Opts>(
    async ({ userPk, orderId }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartUserId = getCartUserId(cart);

      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/orders/${userPk}/${orderId}/confirm/vipps`
      );

      if (!response.ok) {
        throw await response.json();
      }

      return await response.json();
    },
    {
      retry: 4,
      retryDelay: 500,
      onSuccess: async (order) => {
        await router
          .push(`/orders/${order.user.uid}/${order.code}`)
          .then(() => {
            clearCart();
            resetLoggedInUser();
          });
      },
      onError: async () => {
        await router.push('/cart');
      },
    }
  );
}

export function useSendOrderToPosMutation() {
  const { data: cart } = useCart();
  const { clearCart } = useCartStore();
  const { resetLoggedInUser } = useAuthStore();

  const router = useRouter();

  return useMutation<Order>(
    async () => {
      if (!cart) {
        return Promise.resolve;
      }

      const cartUserId = getCartUserId(cart);
      const cartId = getCartId(cart);

      const paymentResponse = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/paymentmode?paymentMode=posB2h&mobilePhone=`,
        {
          method: 'POST',
        }
      );

      if (!paymentResponse.ok) {
        throw await paymentResponse.json();
      }

      const orderResponse = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/orders?cartId=${cartId}`,
        { method: 'POST' }
      );

      const data = await orderResponse.json();

      if (!orderResponse.ok) {
        throw data;
      }

      return data;
    },
    {
      onSuccess: async (order) => {
        await router
          .push(`/orders/${order.user.uid}/${order.code}`)
          .then(() => {
            resetLoggedInUser();
            clearCart();
          });
      },
    }
  );
}

export function usePayOrderWithGiftCardMutation() {
  const { data: cart } = useCart();
  const { clearCart } = useCartStore();
  const { resetLoggedInUser } = useAuthStore();

  const router = useRouter();

  return useMutation<Order>(
    async () => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartUserId = getCartUserId(cart);
      const cartId = getCartId(cart);

      const orderResponse = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/orders?cartId=${cartId}`,
        { method: 'POST' }
      );

      const data = await orderResponse.json();

      if (!orderResponse.ok) {
        throw data;
      }

      return data;
    },
    {
      onSuccess: async (order) => {
        await router
          .push(`/orders/${order.user.uid}/${order.code}`)
          .then(() => {
            resetLoggedInUser();
            clearCart();
          });
      },
    }
  );
}

export function useApplyDiscountMutation() {
  const { data: cart } = useCart();

  type Opts = {
    percentage: number;
    freeShipping: boolean;
  };

  return useMutation<unknown, unknown, Opts>(
    ({ percentage, freeShipping }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      return fetchWithAuth(
        `${config.apiBaseUrl}/users/${cartUserId}/applyDiscount?cartId=${cartId}&percentage=${percentage}&freeShipping=${freeShipping}`,
        { method: 'POST' }
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('cart'),
    }
  );
}

export function useAddVoucherMutation() {
  const { data: cart } = useCart();

  type Opts = {
    voucherCode: string;
  };

  type Error = {
    message: string;
  };

  return useMutation<unknown, Error, Opts>(
    async ({ voucherCode }) => {
      if (!cart) {
        return Promise.resolve();
      }
      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/vouchers?voucherId=${voucherCode}`,
        {
          method: 'POST',
        }
      );

      if (!response.ok) {
        const data = await response.json();

        throw Array.isArray(data.errors)
          ? data.errors[0]
          : {
              message: 'Unkown error',
            };
      }

      return true;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('vouchers');
        queryClient.invalidateQueries('cart');
        return;
      },
    }
  );
}

export function useDeleteVoucherMutation() {
  const { data: cart } = useCart();

  type Opts = {
    /**
     * The voucher code to delete. Use the `voucher.voucherCode` param, not
     * `voucher.code`.
     */
    voucherCode: string;
  };

  return useMutation<unknown, unknown, Opts>(
    async ({ voucherCode }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/vouchers/${voucherCode}`,
        {
          method: 'DELETE',
        }
      );

      return true;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('vouchers');
        return queryClient.invalidateQueries('cart');
      },
    }
  );
}

type ValidateGiftcardMutationResponse =
  | {
      valid: true;
      currency: string;
      value: number;
    }
  | {
      valid: false;
      value: 0;
    };

export function useValidateGiftcardMutation({
  onSuccess,
}: {
  onSuccess?: (response: ValidateGiftcardMutationResponse) => void;
} = {}) {
  type Opts = {
    multicode: string;
    pin: string;
  };

  return useMutation<ValidateGiftcardMutationResponse, unknown, Opts>(
    async ({ multicode, pin }) => {
      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/giftcards/validate`,
        { method: 'POST', queryParams: { multicode, pin } }
      );

      if (!response.ok) {
        throw await response.json();
      }

      return response.json();
    },
    {
      onSuccess,
    }
  );
}

export function useApplyGiftcardMutation() {
  const { data: cart } = useCart();

  type Opts = {
    multicode: string;
    pin: string;
    amount: number;
  };

  return useMutation<unknown, unknown, Opts>(
    async ({ multicode, pin, amount }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/giftcards`,
        {
          method: 'POST',
          queryParams: {
            multicode,
            pin,
            amount,
          },
        }
      );

      if (!response.ok) {
        throw await response.json();
      }

      return {
        ok: true,
      };
    },
    {
      onSuccess: () => queryClient.invalidateQueries('cart'),
    }
  );
}

export function useDeleteGiftcardMutation() {
  const { data: cart } = useCart();

  type Opts = {
    multicode: string;
  };

  return useMutation<unknown, unknown, Opts>(
    async ({ multicode }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}/giftcards`,
        {
          method: 'DELETE',
          queryParams: {
            multicode,
          },
        }
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('cart'),
    }
  );
}

export function useUpdateCartMutation() {
  const { data: cart } = useCart();

  type Opts = {
    storeName?: string;
    deliveryComment?: string;
    deliveryEmail?: string;
    useKlarnaAddressForShipping?: boolean;
    splitBySupplierProduct?: boolean;
  };

  return useMutation<unknown, unknown, Opts>(
    async ({
      storeName,
      deliveryComment,
      deliveryEmail,
      useKlarnaAddressForShipping,
      splitBySupplierProduct,
    }) => {
      if (!cart) {
        return Promise.resolve();
      }

      const cartId = getCartId(cart);
      const cartUserId = getCartUserId(cart);

      const params = {
        pickupStore: storeName,
        deliveryComment,
        deliveryEmail,
        useKlarnaAddressForShipping,
        splitBySupplierProduct,
      };

      const filteredParams = Object.keys(params).reduce((acc, key) => {
        if (params[key] !== undefined && params[key] !== null) {
          acc[key] = params[key];
        }
        return acc;
      }, {} as Record<keyof typeof params, string>);

      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${cartUserId}/carts/${cartId}`,
        {
          method: 'POST',
          queryParams: filteredParams,
        }
      );

      if (!response.ok) {
        return await response.json();
      }

      return true;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cart');
      },
      mutationKey: '/cart/update',
    }
  );
}

export function useGetLoggedInUserLastCart() {
  return useMutation(async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/carts`
    );

    return response;
  });
}

export function useInitialCartMergeMutation() {
  const { setCart } = useCartStore();
  const { mutateAsync: createCart } = useCreateCartMutation();
  const { mutateAsync: getCartHistory } = useGetLoggedInUserLastCart();

  return useMutation(
    async ({
      prevCartId,
      isAnonymous,
    }: {
      prevCartId: string;
      isAnonymous: boolean;
    }) => {
      const cartHistoryResponse = await getCartHistory();

      const cartData = await cartHistoryResponse.json();

      // Check if there is cart history
      const latestUserCart = cartData?.carts?.[0];

      if (latestUserCart) {
        // Set latestUserCart as current cart
        setCart(latestUserCart);
      }

      if (!prevCartId || !isAnonymous) {
        return;
      }

      // Merge or create cart based on the presence of latestUserCart
      // TODO Check if need to create empty cart before mergin oldCartId
      await createCart({
        userId: 'current',
        oldCartId: prevCartId,
        toMergeCartGuid: latestUserCart ? latestUserCart.guid : undefined,
      });
    }
  );
}
