import * as Sentry from '@sentry/nextjs';
import { useStorePreferences } from 'jernia.no/hooks/selectedStore';
import router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { createStore } from '@jernia/shared/state/createStore';
import { queryClient } from '@jernia/shared/state/query-client';

import { config } from '@jernia/shared/lib/config';

import { urlEncodedFormData } from '../helpers/encode-form-data';
import { Company, CompanyRegistrationDataByName } from '../types/rest/company';
import { ConsentTemplatesResponse } from '../types/rest/consent';
import { RestError } from '../types/rest/error';
import { RestMutationResponse } from '../types/rest/response';
import { User, UserGroup } from '../types/rest/user';
import { OauthToken, VippsLoginResultData } from '../types/rest/vipps';
import { useUserProfile } from './account/queries';
import { useInitialCartMergeMutation } from './cart/mutations';
import { useRemovedEntriesStore } from './cart/removed-entries-store';
import { useCartStore } from './cart/store';
import { useCustomerStore } from './customer';
import { fetchWithAuth, fetchWithAuthOptional } from './fetch';

import type { AuthenticationResponseSuccess } from '@jernia/shared/types/rest/authentication';
import type { EmployeeSuccessResponse } from '@jernia/shared/types/rest/emloyee';
import { PointOfService, Stores } from '@jernia/shared/types/rest/stores';

type Session = {
  id: string;
  expiresAt: number;
};

type AuthState = {
  accessToken: string;
  userId: string;
  userRole: UserGroup;

  setUserRole: (user: User) => void;
  setAccessToken: (accessToken: string) => void;
  loginSuccess: (result: AuthenticationResponseSuccess) => void;
  setLoggedInUser: (userId: string) => void;
  resetLoggedInUser: () => void;
  logout: (disableRedirect?: boolean) => void;
  getAccessToken: () => string;
  setIsRefreshingToken: (isRefreshingToken: boolean) => void;
  isRefreshingToken: boolean;

  setVippsRedirectUrl: (url: string) => void;
  vippsRedirectUrl: string;

  customerId: string;
  setCustomerId: (customerId: string) => void;

  refreshSession: ({
    generateNewSession,
  }: {
    generateNewSession?: boolean;
  }) => void;
  session: Session;
};

function getDefaultAccessToken() {
  return JSON.parse(localStorage.getItem('authSession'))?.accessToken ?? '';
}

export const useAuthStore = createStore<AuthState>((set, get) => ({
  accessToken:
    typeof localStorage !== 'undefined' ? getDefaultAccessToken() : '',

  getAccessToken: () => get().accessToken,

  userId:
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('userId') || 'anonymous'
      : 'anonymous',

  setAccessToken: (accessToken) => set({ accessToken }),

  userRole:
    typeof localStorage !== 'undefined'
      ? (localStorage.getItem('userRole') as UserGroup) ?? 'anonymous'
      : 'anonymous',

  setUserRole: (user?: User) => {
    let role = 'anonymous';

    if (user.b2b) {
      role = 'b2b';
    } else if (user.jerniaEmployeeNumber) {
      role = 'employee';
    } else {
      role = 'customerclub';
    }

    set({ userRole: role as UserGroup });
    localStorage.setItem('userRole', role);
  },

  loginSuccess: (result: AuthenticationResponseSuccess) => {
    set({ accessToken: result['access_token'] });

    const userId = localStorage.getItem('userId');

    if (!userId || userId === 'anonymous') {
      get().setLoggedInUser('current');
    }

    const expiredTime = +new Date() + result['expires_in'] * 1000;

    localStorage.setItem(
      'authSession',
      JSON.stringify({
        accessToken: result['access_token'],
        refreshToken: result['refresh_token'],
        expiresAt: expiredTime,
      })
    );
  },

  setLoggedInUser(userId: string) {
    set({ userId });
    localStorage.setItem('userId', userId);
  },

  resetLoggedInUser() {
    set({ userId: 'anonymous' }), localStorage.removeItem('userId');
  },

  logout: (disableRedirect = false) => {
    set({
      accessToken: '',
      userId: 'anonymous',
      userRole: 'anonymous',
      customerId: '',
    });

    localStorage.removeItem('userId');
    localStorage.removeItem('authSession');
    localStorage.removeItem('tmpAddressInfo');
    localStorage.setItem('userRole', 'anonymous');
    localStorage.removeItem('customerId');
    useStorePreferences.getState().setFavoriteStore('');

    useCartStore.getState().clearCart();
    useRemovedEntriesStore.getState().clearEntries();
    get().refreshSession({ generateNewSession: true });

    if (config.site === 'self-checkout') {
      router.push('/');
    } else {
      queryClient.invalidateQueries('user');

      if (!disableRedirect) {
        router.push('/login');
      }
    }
  },

  setIsRefreshingToken: (isRefreshingToken: boolean) => {
    set({ isRefreshingToken });
  },
  isRefreshingToken: false,

  setVippsRedirectUrl: (url: string) => {
    set({ vippsRedirectUrl: url });
    localStorage.setItem('vippsRedirectUrl', url);
  },
  vippsRedirectUrl:
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('vippsRedirectUrl') ?? ''
      : '',

  customerId:
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('customerId') ?? ''
      : '',
  setCustomerId: (customerId: string) => {
    set({ customerId });
    localStorage.setItem('customerId', customerId);
  },

  refreshSession: ({
    generateNewSession = false,
  }: {
    generateNewSession?: boolean;
  }) => {
    const currentSession = get().session;
    const newExpiresDate = +new Date() + 1000 * 60 * 60 * 24 * 7; // 7 days

    const isSessionValid = currentSession?.expiresAt > +new Date();

    let sessions: Session;

    if (currentSession && isSessionValid && !generateNewSession) {
      sessions = {
        ...currentSession,
        expiresAt: newExpiresDate,
      };
    } else {
      const id = crypto.randomUUID();
      sessions = { id, expiresAt: newExpiresDate };
    }

    set({ session: sessions });
    localStorage.setItem('trackingSession', JSON.stringify(sessions));
  },
  session:
    typeof localStorage !== 'undefined'
      ? JSON.parse(localStorage.getItem('trackingSession') ?? '{}')
      : {},
}));

export function useLoggedInEmployee() {
  return useQuery<EmployeeSuccessResponse>('employee', async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/asagents/current`
    );

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  });
}

export function useLoggedInUserQuery() {
  const { userId } = useAuthStore();

  return useQuery<User>(
    ['user', userId],
    async () => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${userId}`
      );

      if (!response.ok) {
        throw await response.json();
      }

      return await response.json();
    },
    {
      enabled: userId !== 'anonymous',
    }
  );
}

export function useStores() {
  return useQuery<PointOfService[]>(
    'stores',
    async () => {
      const resp = await fetch(`${config.apiBaseUrl}/stores/jerniaHub`);
      const { pointOfServices }: Stores = await resp.json();
      return pointOfServices;
    },
    {
      placeholderData: [],
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}

export function useStore(storeId: string) {
  return useQuery<PointOfService>(
    ['store', storeId],
    async () => {
      const resp = await fetch(
        `${config.apiBaseUrl}/stores/${storeId}?fields=FULL`
      );
      const pointOfService: PointOfService = await resp.json();
      return pointOfService;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!storeId,
    }
  );
}

export function useChangeStoreMutation() {
  type Opts = {
    storeId: string;
  };

  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, Opts>(
    async ({ storeId }) => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/asagents/current/store?store=${storeId}`,
        {
          method: 'PATCH',
        }
      );

      if (!response.ok) {
        throw await response.json();
      }

      return Promise.resolve();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee');
      },
    }
  );
}

export function useRequireAuthentication(): {
  showSplash: boolean;
} {
  const router = useRouter();
  const queryClient = useQueryClient();

  const { setAccessToken, loginSuccess, resetLoggedInUser } = useAuthStore();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  type Result =
    | {
        type: 'valid';
        accessToken: string;
      }
    | {
        type: 'refreshed';
        result: AuthenticationResponseSuccess;
      };

  const restoreSessionMutation = useMutation<Result>(
    async () => {
      const sessionString = localStorage.getItem('authSession');
      if (!sessionString) {
        throw new Error('No session');
      }

      // @TODO: Type this
      let session;
      try {
        session = JSON.parse(sessionString);
      } catch (e) {
        throw new Error(
          `Failed to parse invalid session string: ${sessionString}`
        );
      }

      if (!session) {
        throw new Error('No session string');
      }

      if (session.expiresAt < +new Date()) {
        const response = await fetch('/api/login/refresh', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refreshToken: session.refreshToken,
          }),
        });

        const result = await response.json();

        if (!response.ok) {
          throw new Error(
            `Failed to authenticate user. ${JSON.stringify(result)}`
          );
        }

        return {
          type: 'refreshed',
          result,
        };
      }

      return {
        type: 'valid',
        accessToken: session.accessToken,
      };
    },
    {
      onSuccess: async (result) => {
        switch (result.type) {
          case 'valid':
            setAccessToken(result.accessToken);
            break;
          case 'refreshed':
            loginSuccess(result.result);
            break;
        }

        setIsAuthenticated(true);

        try {
          await useCustomerStore
            .getState()
            .loginAsCustomer(useAuthStore.getState().userId);
        } catch (e) {
          resetLoggedInUser();
        }

        queryClient.invalidateQueries('cart');
      },
      onError: (e) => {
        Sentry.captureException(e);
        console.error(e);

        router.push('/login');
      },
    }
  );

  const { mutate } = restoreSessionMutation;
  useEffect(() => {
    if (
      router.asPath.startsWith('/login') ||
      router.asPath.startsWith('/_vipps-checkout')
    ) {
      return;
    }

    mutate();
  }, [mutate, router]);

  return {
    showSplash:
      !isAuthenticated &&
      !router.asPath.startsWith('/login') &&
      !router.asPath.startsWith('/_vipps-checkout'),
  };
}

export type ConsentTemplate = {
  consentTemplateId: string;
  consentTemplateVersion: number;
  consentGiven: boolean;
};

export type RegisterUserProps = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  uid: string;
  password: string;
  orgNumber?: string;
  consentTemplates: ConsentTemplate[];
};

export function useRegisterUserMutation() {
  return useMutation<RestMutationResponse, RestError[], RegisterUserProps>(
    async (body) => {
      const { consentTemplates, ...bodyPayload } = body;

      // Construct the consents header value
      const consentsHeaderValue = consentTemplates.map((consent) => ({
        templateCode: consent.consentTemplateId,
        templateVersion: consent.consentTemplateVersion,
        consentState: consent.consentGiven ? 'GIVEN' : null,
      }));

      const encodedConsentsHeaderValue = encodeURIComponent(
        JSON.stringify(consentsHeaderValue)
      );
      const response = await fetch(`${config.apiBaseUrl}/users`, {
        method: 'POST',
        headers: {
          'X-Anonymous-Consents': encodedConsentsHeaderValue,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyPayload),
      });

      return response.json();
    }
  );
}

export function useAvailableConsentTemplatesQuery(customerId?: string) {
  const { userId } = useAuthStore();
  return useQuery<ConsentTemplatesResponse['consentTemplates']>(
    [`consentTemplates-${customerId || userId}`],
    async () => {
      const id = customerId || userId;

      const response = await fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${id}/consenttemplates`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch consent templates');
      }

      const data = await response.json();

      return data.consentTemplates;
    }
  );
}

type UserForgotPasswordProps = {
  email: string;
};

type Variant = 'b2c' | 'b2b';
export function useUserForgotPasswordMutation(variant: Variant = 'b2c') {
  return useMutation<Response, unknown, UserForgotPasswordProps>(
    async (props) => {
      const params = new URLSearchParams();
      params.set('userId', props.email);
      params.set('b2b', variant === 'b2b' ? 'true' : 'false');

      const url = `${
        config.apiBaseUrl
      }/forgottenpasswordtokens?${params.toString()}`;
      const response = await fetch(url, {
        method: 'POST',
      });

      return response;
    }
  );
}

export async function getOrdersAccessToken() {
  const accessTokenRes = await fetch(
    `${process.env.OAUTH_SERVER}/authorizationserver/oauth/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: Object.entries({
        client_id: process.env.OAUTH_CLIENT_ID_ORDERS,
        client_secret: process.env.OAUTH_CLIENT_SECRET,
        grant_type: 'client_credentials',
      })
        .map(([key, value]) => `${key}=${value}`)
        .join('&'),
    }
  );

  const token = await accessTokenRes.json();

  return token;
}

type ResetPasswordFormData = {
  token: string;
  newPassword: string;
};

export function useResetPasswordMutation() {
  return useMutation<Response, unknown, ResetPasswordFormData>(
    async (props) => {
      const response = await fetch(`${config.apiBaseUrl}/resetpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(props),
      });

      return response;
    }
  );
}
type B2bRegistrationDataByNumberProps = {
  number: string;
};

export function useB2bRegistrationDataByNumberMutation() {
  return useMutation<Response, unknown, B2bRegistrationDataByNumberProps>(
    async (props) => {
      const url = `${config.apiBaseUrl}/companies/${props.number}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response not ok');
      }

      return response;
    }
  );
}

export function useB2bCompanyDataQuery(number: string) {
  return useQuery<Company>(
    ['b2bCompanyData', number],
    async () => {
      const url = `${config.apiBaseUrl}/companies/${number}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response not ok');
      }

      return await response.json();
    },
    {
      enabled: !!number,
    }
  );
}

type B2bRegistrationDataByNameProps = {
  query: string;
  currentPage?: number;
  pageSize?: number;
};

export function useB2bRegistrationDataByQueryMutation() {
  return useMutation<
    CompanyRegistrationDataByName,
    unknown,
    B2bRegistrationDataByNameProps
  >(
    async (props) => {
      const { query, currentPage = 0, pageSize = 10 } = props;
      const queryParams = urlEncodedFormData({
        query,
        currentPage,
        pageSize,
      });

      const url = `${config.apiBaseUrl}/companies?${queryParams}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response not ok');
      }

      return await response.json();
    },
    {
      mutationKey: '/account/b2b/registration-data',
    }
  );
}

export const useVippsLoginCallbackMutation = ({
  ciba = false,
}: {
  ciba?: boolean;
}) => {
  const { getCartId } = useCartStore();
  const { vippsRedirectUrl, setVippsRedirectUrl } = useAuthStore();

  const { handleLoginWithCart } = useHandleLoginWithCart();

  const cartIdBeforeLogin = getCartId();
  const router = useRouter();
  return useMutation<
    { state?: string; data: VippsLoginResultData },
    unknown,
    { code: string; state?: string }
  >(
    async (props) => {
      const { code, state } = props;

      const params = new URLSearchParams();
      params.set('code', code);
      if (state) {
        params.set('state', state);
      }

      const url = ciba
        ? `${config.apiBaseUrl}/vipps/login/callback/ciba?${params}`
        : `${config.apiBaseUrl}/vipps/login/callback?${params}`;

      const response = await fetch(url, {
        method: 'POST',
      });

      if (!response.ok) {
        throw await response.json();
      }

      return {
        state,
        data: await response.json(),
      };
    },
    {
      onSuccess: async (data) => {
        const { state, data: loginData } = data;

        const { status, accessToken, vippsAccessToken, userId } = loginData;

        const shouldLogin = ['AUTHENTICATED', 'NEW_ACCOUNT'].includes(status);

        if (shouldLogin) {
          handleLoginWithCart({
            accessToken,
            cartIdBeforeLogin,
          });
        }
        switch (status) {
          case 'AUTHENTICATED':
            router.push(vippsRedirectUrl || '/my-account').then(() => {
              setVippsRedirectUrl('');
            });
            break;
          case 'NEW_ACCOUNT':
            router.push('/vipps/login/new-account');
            break;
          case 'EXISTING_ACCOUNT':
            router.push(
              `/vipps/login/existing-account?vippsAccessToken=${vippsAccessToken}&state=${
                state ?? ''
              }&userId=${userId}`
            );
            break;
        }
      },
      onError: (error) => {
        localStorage.removeItem('authSession');
        router.push('/login');
      },
    }
  );
};

export const useVippsLoginExistingAccountMutation = () => {
  const { getCartId } = useCartStore();
  const { vippsRedirectUrl, setVippsRedirectUrl } = useAuthStore();

  const cartIdBeforeLogin = getCartId();
  const { handleLoginWithCart } = useHandleLoginWithCart();
  const router = useRouter();

  return useMutation<
    VippsLoginResultData,
    unknown,
    { state?: string; accessToken: string }
  >(
    async (props) => {
      const params = new URLSearchParams();
      params.set('accessToken', props.accessToken);
      if (props.state) {
        params.set('state', props.state);
      }

      const response = await fetch(
        `${config.apiBaseUrl}/vipps/login/existing-account?${params}`,
        {
          method: 'POST',
        }
      );

      // TODO FIX THIS
      // if 302 redirect to location header
      if (!response.ok) {
        throw await response.json();
      }

      return await response.json();
    },
    {
      onSuccess: async (loginData) => {
        const { accessToken } = loginData;
        handleLoginWithCart({
          accessToken,
          cartIdBeforeLogin,
        });
        router.push(vippsRedirectUrl || '/my-account').then(() => {
          setVippsRedirectUrl('');
        });
      },
    }
  );
};

export const useVippsLoginRegisterMutation = () => {
  const { getCartId } = useCartStore();
  const { vippsRedirectUrl, setVippsRedirectUrl } = useAuthStore();

  const cartIdBeforeLogin = getCartId();
  const { handleLoginWithCart } = useHandleLoginWithCart();
  const router = useRouter();

  return useMutation<
    VippsLoginResultData,
    unknown,
    { accessToken: string; state?: string }
  >(
    async (props) => {
      const params = new URLSearchParams();
      params.set('accessToken', props.accessToken);
      if (props.state) {
        params.set('state', props.state);
      }

      const response = await fetch(
        `${config.apiBaseUrl}/vipps/login/register?${params}`,
        {
          method: 'POST',
        }
      );

      if (!response.ok) {
        throw await response.json();
      }

      return await response.json();
    },
    {
      onSuccess: async (loginData) => {
        const { accessToken } = loginData;
        handleLoginWithCart({
          accessToken,
          cartIdBeforeLogin,
        });
        router.push(vippsRedirectUrl || '/my-account').then(() => {
          setVippsRedirectUrl('');
        });
      },
    }
  );
};

export const useVippsLoginMutation = () => {
  return useMutation<string, unknown>(async () => {
    const response = await fetch(`${config.apiBaseUrl}/vipps/login`);

    return response.text();
  });
};

export function useHandleLoginWithCart() {
  const { setCartUid, cartUid } = useCartStore();
  const { loginSuccess, setUserRole, setCustomerId, refreshSession } =
    useAuthStore();
  const { mutate: mergeCart } = useInitialCartMergeMutation();
  const { refetch: refetchUser } = useUserProfile();

  async function handleLoginWithCart({
    accessToken,
    cartIdBeforeLogin,
  }: {
    accessToken: OauthToken;
    cartIdBeforeLogin: string;
  }) {
    setCartUid('current');

    loginSuccess(accessToken);

    // mergeCart({
    //   prevCartId: cartIdBeforeLogin,
    //   isAnonymous: cartUid === 'anonymous',
    // });
    const { data: user } = await refetchUser();

    setCustomerId(user.customerId);

    refreshSession({ generateNewSession: true });
    setUserRole(user);
    mergeCart({
      prevCartId: cartIdBeforeLogin,
      isAnonymous: cartUid === 'anonymous',
    });
  }
  return {
    handleLoginWithCart,
  };
}

type UserOnboardingProps = {
  userId: string;
  storeId?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  password: string;
  uid?: string;
  consents: {
    consentTemplateId: string;
    consentTemplateVersion: string;
  }[];
};

export function useUserOnboardingMutation() {
  return useMutation<RestMutationResponse, RestError[], UserOnboardingProps>(
    async (body) => {
      const {
        userId,
        storeId,
        firstName,
        lastName,
        mobileNumber,
        password,
        uid,
        consents,
      } = body;

      await Promise.allSettled(
        consents.map((consent) => {
          const consentParams = new URLSearchParams();
          consentParams.append('consentTemplateId', consent.consentTemplateId);
          consentParams.append(
            'consentTemplateVersion',
            consent.consentTemplateVersion || '0'
          );
          return fetch(
            `${config.apiBaseUrl}/users/${userId}/consents?${consentParams}`,
            {
              method: 'POST',
            }
          );
        })
      );

      const formData = new URLSearchParams();
      formData.append('password', password);
      if (storeId) formData.append('storeId', storeId);
      if (firstName) formData.append('firstName', firstName);
      if (lastName) formData.append('lastName', lastName);
      if (mobileNumber) formData.append('mobileNumber', mobileNumber);
      if (uid) formData.append('uid', uid);

      const response = await fetch(
        `${config.apiBaseUrl}/users/${userId}/onboard`,
        {
          method: 'POST',
          body: formData.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (!response.ok) {
        return response.json();
      }

      return true;
    }
  );
}
