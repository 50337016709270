import { useStorePreferences } from 'jernia.no/hooks/selectedStore';
import { useQuery } from 'react-query';

import { config } from '@jernia/shared/lib/config';

import { useAuthStore } from '../auth';
import { fetchWithAuth } from '../fetch';

import {
  AccountInvoices,
  B2BAccount,
  B2BProject,
  B2BUserMember,
  GiftReceipt,
  PhoneVerificationStatus,
  SingleInvoice,
  UnpaidInvoice,
} from '@jernia/shared/types/rest/account';
import { User } from '@jernia/shared/types/rest/user';

export function useUserProfile() {
  const { setFavoriteStore } = useStorePreferences();
  const { getAccessToken } = useAuthStore();
  return useQuery<User | null>(
    ['user'],
    async () => {
      const token = getAccessToken();

      if (!token) {
        return Promise.resolve(null);
      }

      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/current`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch user profile');
      }
      return await response.json();
    },
    {
      retry: 3,
      onSuccess: (user) => {
        setFavoriteStore(user?.favoriteStore?.name ?? '');
      },
    }
  );
}

export const usePhoneVerificationStatus = () => {
  return useQuery<PhoneVerificationStatus | null>(
    ['/phone/verify'],
    async () => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/current/phone/verify`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch phone verification status');
      }

      return response.json();
    }
  );
};

export const useGiftReceipts = (orderId: string) => {
  return useQuery<GiftReceipt[]>(
    [`/order/gift-receipt/${orderId}`],
    async () => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/current/orders/${orderId}/giftreceipts`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch gift receipts');
      }
      return response.json();
    }
  );
};

export const useB2BAccount = ({ enabled }: { enabled: boolean }) => {
  return useQuery<B2BAccount | null>(
    ['/b2b/account'],
    async () => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/current/b2b/account`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch B2B account');
      }
      return response.json();
    },
    {
      enabled,
    }
  );
};

export const useAvailableCreditLimits = () => {
  return useQuery<number[]>(['/credit/limits'], async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/b2b/credit/limits`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch available credit limits');
    }
    return response.json();
  });
};

export const useB2BUserMember = ({
  userId,
  enabled,
}: {
  userId: string;
  enabled: boolean;
}) => {
  return useQuery<B2BUserMember>(
    [`/b2b/user/${userId}`],
    async () => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${userId}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch member user');
      }
      return response.json();
    },
    {
      enabled,
    }
  );
};

export const useB2BProjects = () => {
  return useQuery<B2BProject[]>('/b2b/projects', async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/b2b/projects`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch projects');
    }
    return response.json();
  });
};

type AccountInvoicesProps = {
  pageSize?: string;
  page?: string;
  enabled?: boolean;
};

export function useB2bAccountInvoices({
  pageSize = '10',
  page = '1',
  enabled = true,
}: AccountInvoicesProps) {
  return useQuery<AccountInvoices | null>(
    ['/b2b/invoices'],
    async () => {
      const searchParams = {
        pageSize,
        currentPage: page,
      };

      const queryParams = new URLSearchParams(searchParams);

      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/current/b2b/invoices?${queryParams}`
      );

      if (!response.ok) {
        throw new Error('Network response not ok');
      }

      return response.json();
    },
    {
      enabled,
    }
  );
}

export const useB2bAccountUnpaidInvoices = () => {
  return useQuery<UnpaidInvoice[]>(['/b2b/invoices/unpaid'], async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/b2b/invoices/unpaid`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch unpaid invoices');
    }
    return response.json();
  });
};

export const useSingleInvoice = (invoiceId: string) => {
  return useQuery<SingleInvoice>(
    [`/account/b2b/invoice/${invoiceId}`],
    async () => {
      const response = await fetch(`/account/b2b/invoice/${invoiceId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch invoice');
      }
      return response.json();
    }
  );
};

export const useAccountWishlistCount = () => {
  return useQuery<number | null>(['/wishlist-count'], async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/wishlist`,
      {
        method: 'HEAD',
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch wishlist');
    }

    const totalCount = response.headers.get('X-Total-Count') ?? '0';

    return totalCount ? +totalCount : 0;
  });
};

export const useAccountColorListCount = () => {
  return useQuery<number | null>(['/color-list-count'], async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/colorlist`,
      {
        method: 'HEAD',
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch wishlist');
    }

    const totalCount = response.headers.get('X-Total-Count') ?? '0';

    return totalCount ? +totalCount : 0;
  });
};

export const useAccountInvoicesCount = () => {
  return useQuery<number | null>(['/b2b/invoices'], async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/b2b/invoices`,
      {
        method: 'HEAD',
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch invoices');
    }

    const totalCount = response.headers.get('X-Total-Count') ?? '0';

    return totalCount ? +totalCount : 0;
  });
};

export const useAccountProjectsCount = () => {
  return useQuery<number | null>(['/b2b/projects/counter'], async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/b2b/projects`,
      {
        method: 'HEAD',
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch projects');
    }

    const totalCount = response.headers.get('X-Total-Count') ?? '0';

    return totalCount ? +totalCount : 0;
  });
};

export const useInvoiceOptions = () => {
  return useQuery<string[]>(['/b2b/credit/invoicing-options'], async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/current/b2b/credit/invoicing-options`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch invoice options');
    }
    return response.json();
  });
};
