import { StateCreator, StoreApi, UseBoundStore, create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const createStore = <T>(
  store: StateCreator<T, [], []>
): UseBoundStore<StoreApi<T>> => {
  if (process.env.NODE_ENV === 'development') {
    return create(devtools(store as any));
  }
  return create(store);
};
