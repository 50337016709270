import { useStorePreferences } from 'jernia.no/hooks/selectedStore';
import Router from 'next/router';
import { useQuery } from 'react-query';

import { config } from '@jernia/shared/lib/config';

import { useAuthStore } from '../auth';
import { fetchWithAuthOptional } from '../fetch';
import { getCartId } from './shared';
import { useCartStore } from './store';

import { DeliveryMethod } from '@jernia/shared/types/delivery-methods';
import type { Cart, Voucher } from '@jernia/shared/types/rest/cart';
import { RestError } from '@jernia/shared/types/rest/error';

export function useCart() {
  const { cartId, cartUid, clearCart } = useCartStore();

  const { setSelectedStore } = useStorePreferences();

  return useQuery<Cart | null, RestError>(
    ['cart', cartId, cartUid],
    async () => {
      if (cartId && cartUid) {
        const response = await fetchWithAuthOptional(
          `${config.apiBaseUrl}/users/${cartUid}/carts/${cartId}?fields=FULL`
        );

        const result = await response.json();

        if (!response.ok) {
          throw result as RestError;
        }

        return result;
      }

      return null;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchOnMount: false,

      onError: (error) => {
        const notFound = error?.errors?.some(
          (err) => err.reason === 'notFound'
        );

        const isForbidden = error?.errors?.some(
          (err) => err.type === 'ForbiddenError'
        );

        if (notFound || isForbidden) {
          clearCart();
        }

        if (config.site === 'self-checkout') {
          // Log the user out if they don't have a cart. They need to log back
          // in to create a new self checkout cart.
          Router.push('/');
        }
      },
      onSuccess: (data) => {
        setSelectedStore(data?.deliveryPointOfService?.name ?? '');
      },
    }
  );
}

export function useDeliveryMethods() {
  const { data: cart } = useCart();
  const { userId } = useAuthStore();

  const cartId = getCartId(cart);

  return useQuery<DeliveryMethod[]>(
    ['deliveryMethods', cartId, userId],
    () => {
      if (cartId && cart?.deliveryAddress) {
        return fetchWithAuthOptional(
          `${config.apiBaseUrl}/users/${userId}/carts/${cartId}/deliverymodes`
        )
          .then((resp) => resp.json())
          .then((result) => result.deliveryModes);
      }

      return [];
    },
    {
      enabled: Boolean(cart?.deliveryAddress) && Boolean(cartId),
    }
  );
}

export const useGetKlarnaCheckoutHtml = ({
  enable = false,
}: {
  enable: boolean;
}) => {
  const { data: cart } = useCart();
  const { userId } = useAuthStore();

  const cartId = getCartId(cart);

  const klarnaId = cart?.klarnaOrderId;

  return useQuery<string>(
    ['klarna-checkout-html', klarnaId],
    async () => {
      if (!cartId) return Promise.resolve(null);

      let url = `${config.apiBaseUrl}/users/${userId}/carts/${cartId}/klarna/`;

      if (klarnaId) {
        url += `update`;
      } else {
        url += `create`;
      }

      let response = await fetchWithAuthOptional(url);
      if (!response.ok && klarnaId) {
        const url = `${config.apiBaseUrl}/users/${userId}/carts/${cartId}/klarna/create`;
        response = await fetchWithAuthOptional(url);
      }

      if (!response.ok) {
        throw new Error('Failed to fetch Klarna checkout HTML');
      }
      return response.text();
    },
    {
      enabled: Boolean(cartId) && enable,
      retry: false,
    }
  );
};

export const useGetVouchers = () => {
  const { data: cart } = useCart();

  const cartId = getCartId(cart);
  const { userId } = useAuthStore();

  return useQuery<Voucher[]>(
    ['vouchers', cartId],
    () => {
      return fetchWithAuthOptional(
        `${config.apiBaseUrl}/users/${userId}/carts/${cartId}/vouchers`
      )
        .then((resp) => resp.json())
        .then((result) => result.vouchers);
    },
    {
      enabled: Boolean(cartId),
    }
  );
};
