import { useRouter } from 'next/router';
import { useMutation } from 'react-query';

import { useAuthStore, useHandleLoginWithCart } from '../auth';
import { useCartStore } from '../cart/store';

type UserData =
  | {
      access_token: string;
      expires_in: number;
      refresh_token: string;
      scope: string;
      token_type: string;
    }
  | {
      error: string;
    };

export function useUserLoginMutation(redirectUrl?: string) {
  type UserLoginProps = {
    username?: string;
    password?: string;
    b2b?: boolean;
  };

  const { getCartId } = useCartStore();
  const { handleLoginWithCart } = useHandleLoginWithCart();
  const cartIdBeforeLogin = getCartId();
  const router = useRouter();

  return useMutation<Response, unknown, UserLoginProps>(
    async (props) => {
      return await fetch(`/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(props),
      });
    },
    {
      onSuccess: async (response) => {
        const data = (await response.json()) as UserData;

        if ('error' in data) {
          throw new Error(data.error);
        }

        if (data.access_token) {
          await handleLoginWithCart({
            accessToken: data,
            cartIdBeforeLogin,
          });

          if (redirectUrl) {
            router.push(redirectUrl);
          }
        }
      },
    }
  );
}

export function setAnonymousUser() {
  const cartState = useCartStore.getState();
  const authState = useAuthStore.getState();

  authState.userRole = 'anonymous';
  authState.userId = 'anonymous';
  authState.accessToken = '';
  localStorage.removeItem('userId');
  localStorage.removeItem('authSession');
  localStorage.setItem('userRole', 'anonymous');
  cartState.clearCart();
  return;
}

export async function refreshAuthToken(session: any) {
  const authState = useAuthStore.getState();

  authState.setIsRefreshingToken(true);

  const response = await fetch('/api/login/refresh', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refreshToken: session.refreshToken,
    }),
  });

  if (!response.ok) {
    setAnonymousUser();
    return;
  }

  const result = await response.json();
  authState.setIsRefreshingToken(false);
  authState.loginSuccess(result);
  return;
}

export async function revalidateSession() {
  const authState = useAuthStore.getState();

  const authToken = authState.getAccessToken();

  if (!authToken) {
    return;
  }

  await new Promise((resolve) => {
    if (!authState.isRefreshingToken) {
      resolve(true);
      return;
    }

    const unsub = useAuthStore.subscribe((state) => {
      if (!state.isRefreshingToken) {
        unsub();
        resolve(true);
      }
    });
  });

  const sessionString = localStorage.getItem('authSession');

  let session;
  try {
    session = JSON.parse(sessionString);
  } catch (e) {
    setAnonymousUser();
    return;
  }

  if (!session) {
    setAnonymousUser();
    return;
  }

  const isExpired = session.expiresAt < +new Date();

  if (isExpired) {
    await refreshAuthToken(session);
  }
  return;
}
