// TODO: Populate this with all routes
export const APP_ROUTES = {
  CHECKOUT: {
    STORE: '/checkout/store',
    MULTI: '/checkout/multi',
    LOGIN: '/login/checkout',
    ORDER_CONFIRMATION: '/checkout/orderConfirmation',
  },
  ACCOUNT: {
    MY_COMPANY: '/my-company',
    MY_ACCOUNT: '/my-account',
  },
  AUTH: {
    PHONE_VERIFICATION: '/phone-verification',
  },
} as const;
