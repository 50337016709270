import { create } from 'zustand';

type SelectedStoreState = {
  selectedStoreName: string;
  setSelectedStore: (selectedStoreName: string) => void;
  favoriteStoreName: string;
  setFavoriteStore: (favoriteStoreName: string) => void;
};

export const useStorePreferences = create<SelectedStoreState>((set) => ({
  selectedStoreName:
    typeof sessionStorage !== 'undefined'
      ? sessionStorage.getItem('selectedStoreName') || ''
      : '',
  setSelectedStore: (selectedStoreName: string) => {
    set({ selectedStoreName });
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.setItem('selectedStoreName', selectedStoreName);
    }
  },
  favoriteStoreName:
    typeof sessionStorage !== 'undefined'
      ? sessionStorage.getItem('favoriteStoreName') || ''
      : '',
  setFavoriteStore: (favoriteStoreName: string) => {
    set({ favoriteStoreName });
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.setItem('favoriteStoreName', favoriteStoreName);
    }
  },
}));
