import * as Sentry from '@sentry/nextjs';

import { RestError } from '../types/rest/error';
import { useAuthStore } from './auth';
import { revalidateSession } from './auth/user';

interface FetchOptions extends Omit<RequestInit, 'body'> {
  /**
   * Request will be sent as form data with Conent-Type: application/x-www-form-urlencoded.
   * Body may be passed as an object of key => value which will be formatted
   * properly.
   */
  sendAsForm?: boolean;
  sendAsMultipart?: boolean;
  body?:
    | RequestInit['body']
    | {
        [key: string]: string | number;
      }
    | FormData
    | FileList
    | {
        attachments: any;
      };
  queryParams?: Record<string, string | boolean | number>;
  skipAuth?: boolean;
}

export async function getAccessToken(skipAuth = false) {
  if (skipAuth) {
    return undefined;
  }

  const accessToken = useAuthStore.getState().getAccessToken();

  return accessToken;
}

export async function fetchWithAuth(
  url: string,
  opts: FetchOptions = {}
): Promise<Response> {
  const method = opts.method ?? opts.sendAsForm ? 'POST' : 'GET';

  const skipAuth = opts.skipAuth ?? false;

  await revalidateSession();

  const headers = new Headers({
    Accept: 'application/json',
    ...opts.headers,
  });

  const accessToken = await getAccessToken(skipAuth);

  if (!accessToken && !skipAuth) {
    return Promise.reject('Not authorized');
  }
  if (!skipAuth) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  if (
    method !== 'GET' &&
    !headers.has('content-type') &&
    !opts.sendAsForm &&
    !opts.sendAsMultipart
  ) {
    headers.set('Content-Type', 'application/json');
  }

  if (opts.sendAsForm) {
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
  }

  if (opts.queryParams) {
    const queryParamsString = Object.entries(opts.queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    url = `${url}?${queryParamsString}`;
  }

  if (
    opts.sendAsForm &&
    opts.body &&
    typeof opts.body === 'object' &&
    opts.body !== null
  ) {
    opts.body = Object.entries(opts.body)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  }

  const options = {
    method,
    ...(opts as RequestInit),
    headers,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    switch (response.status) {
      case 401: {
        // @TODO: Handle refresh token (error contains InvalidTokenError).
        Sentry.captureException(`401 returned from [${method}] ${url}`);

        const data = await response.json();

        if (
          data.errors &&
          data.errors[0] &&
          data.errors[0]?.type === 'InvalidTokenError'
        ) {
          useAuthStore.getState().logout();
        }

        break;
      }
    }

    if (response.headers.get('Content-Type')?.includes('application/json')) {
      const cloned = response.clone();
      const data = await cloned.json();
      Sentry.withScope((scope) => {
        console.error(`Failed to fetch ${url}`, data);

        scope.setExtra('url', url);
        scope.setExtra('method', method);

        scope.setExtra('requestBody', opts.body);
        scope.setExtra('responseBody', JSON.stringify(data));

        Sentry.captureException(new Error(`Failed to fetch ${url}`));
      });
    }
  }

  return response;
}

export async function fetchWithAuthOptional(
  url: string,
  opts: FetchOptions = {}
): Promise<Response> {
  const accessToken = useAuthStore.getState().getAccessToken();

  if (!accessToken) {
    return fetchWithAuth(url, {
      ...opts,
      skipAuth: true,
    });
  }

  return fetchWithAuth(url, opts);
}

export const processApiErrors = <T extends string>({
  errors,
  fields,
  genericErrorMessage,
}: {
  errors: RestError['errors'];
  fields: T[];
  genericErrorMessage: string;
}) => {
  if (!errors || !Array.isArray(errors) || errors.length === 0) {
    return null;
  }

  return errors.map((error, index) => {
    const isFieldValid = error.subject && fields.includes(error.subject as T);

    return {
      field: isFieldValid ? (error.subject as T) : undefined,
      message: error.message || genericErrorMessage,
      isFirstError: index === 0 && isFieldValid, // sometimes should use this for focus first error
    };
  });
};
